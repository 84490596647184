<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5 class="mb-4">
								<strong>Cuentas por Pagar - Detalles</strong>
							</h5>
						</div>
						<div class="col-md-12">
							<hr class="hrText" data-content="♦" />
						</div>
					</div>
					<!-- componente -->

					<!-- /componente -->
					<div class="row pt20">
						<div class="col-md-4 text-left">
							<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
						</div>
						<div class="col-md-8 text-right">
							<a-button class="ml5 btn btn-success" icon="save" @click="onSave">Guardar</a-button>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
export default {
	name: 'debtsToPayDetailView',
	computed: {
		spinnerLoaderLabel() {
			return this.$store.state.debtsToPay.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.debtsToPay.spinnerLoader
		},
	},
	methods: {
		onCancel() {
			this.$router.go(-1)
		},
		onSave() {},
	},
}
</script>
